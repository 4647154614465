import * as React from "react";
import { useRef, useEffect } from "react";
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

export const MyCaptchaWidget = (props) => {
    const canvas = useRef();
    let ctx = null;

    // initialize the canvas context
    useEffect(() => {
        // dynamically assign the width and height to canvas
        const canvasEle : any = canvas.current;
        canvasEle.width = 400;
        canvasEle.height = 30;

        // get context of the canvas
        ctx = canvasEle.getContext("2d");
    }, []);

    useEffect(() => {
        writeText({ text: props.formContext.captcha, x: 0, y: 5 });

    }, []);

    // write a text
    const writeText = (info, style = {}) => {
        const { text, x, y } = info;
        const { fontSize = 20, fontFamily = 'Arial', color = 'black', textAlign = 'left', textBaseline = 'top' } : any = style;

        ctx.beginPath();
        ctx.font = fontSize + 'px ' + fontFamily;
        ctx.textAlign = textAlign;
        ctx.textBaseline = textBaseline;
        ctx.fillStyle = color;
        ctx.fillText(text, x, y);
        ctx.stroke();
    }

    const onValueChanged = (value) => {
        props.onChange(value);
    }

    return (
        <div>
            <label className="form-label" htmlFor="customFile">{props.label}</label>
            <div><canvas className={"captcha-canvas"} ref={canvas}></canvas></div>
            <div className={"dta"}>
                <input onChange={(e) => onValueChanged(e.target.value)}
                       type={"text"} className={"form-control"} value={props.value} />
            </div>
        </div>
    );
};