import * as React from "react";
import { hot } from "react-hot-loader";
import {Helmet} from "react-helmet";

import "./../assets/scss/App.scss";
import Formular from "./Formular";

class App extends React.Component<Record<string, unknown>, undefined> {
  public render() {
    return (
      <div className="app">
          <Helmet>
              <meta charSet="utf-8" />
              <title>Formular</title>
          </Helmet>
          <Formular></Formular>
      </div>
    );
  }
}

declare let module: Record<string, unknown>;

export default hot(module)(App);
