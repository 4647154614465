/* eslint-disable prettier/prettier */
import * as React from "react";
import { hot } from "react-hot-loader";
import {Helmet} from "react-helmet";
const localize = require('ajv-i18n');
import "./../assets/scss/App.scss";
import {JSONSchema7} from "json-schema";
import Form from "@rjsf/bootstrap-4";

import classNames from 'classnames'
import 'bootstrap/dist/css/bootstrap.min.css';
import {useState} from "react";
import {Button} from "react-bootstrap";
import axios from "axios";
import {MyHelpWidget} from "./MyHelpWidget";
import {MyFileUploadWidget} from "./MyFileUploadWidget";
import {BarLoader, ClipLoader, RotateLoader} from "react-spinners";


import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import {MyCaptchaWidget} from "./MyCaptchaWidget";

class Formular extends React.Component<Record<string, any>, any> {

    constructor(props) {
        super(props);
        const urlSearchParams = new URLSearchParams(window.location.search);
        // @ts-ignore
        const params = Object.fromEntries(urlSearchParams.entries());

        this.state = {
            step: 0,
            configUrl: params.configUrl,
            error: null,
            loading: false,
            session_id: null,
            formular: params.formular,
            config_formular: null,
            config_id: params.config_id,
            config: null,
            element: {
            }
        }
    }

    componentDidMount() {
        axios.get(this.state.configUrl)
            .then((data: any) => {
                try {
                    const fixedJSON = JSON.parse(data.data.formular);
                    this.setState({config: data.data, session_id: data.data.session_id, config_formular: fixedJSON});
                }
                catch(e) {
                    this.setState({error: e.message, config: null, config_formular: null});
                    console.error(e);
                }
        })
    }

    onFormChanged(e: any) {
        this.setState({element: Object.assign({}, this.state.element, e.formData)})
    }

    onPrevPressed(e: any) {
        this.setState({step: this.state.step-1})
    }

    onNextPressed(e: any) {
        if (this.state.step+1 >= this.getFormular().pages.length) {
            return this.fireFormular(e)
        }
        else {
            this.setState({step: this.state.step+1})
        }
    }

    columnsObjectFieldTemplate ({ properties, description }) {
        return (
            <div>
                <div className='columns row is-multiline has-form-columns'>
                    {properties.map(prop => {
                        const uiSchema = prop.content.props.uiSchema
                        const className = classNames('column', (typeof uiSchema['ui:column'] !== "undefined") ? uiSchema['ui:column']+" bottom" : 'col-md-12 row-like')
                        return <div key={prop.content.key} className={className}>
                            {prop.content}
                        </div>
                    })}
                </div>
                <div><br/></div>
            </div>
        )
    }

    getFormular() {
        if (this.state.config_formular) {
            if (!window.location.hostname.includes("localhost"))
                return this.state.config_formular
        }

        return {
            "errors":  {
                "#/properties/telephone/minLength":  "Bitte geben Sie eine Mindestlänge von 10 Zeichen an"
            },
            "craftnote": {
              "ignore": [
                  "help1",
                  "agb",
                  "help2",
                  "privacy"
              ],
              "translate": {
                "description": "Beschreibung"
              },
              "aggregate": {
              },
              "title": "Reparaturanfrage {street_order} {city_order}"
            },
            "pages":  [
                {
                    "title":  "Beschreibung",
                    "schema":  {
                        "title":  "Beschreibung",
                        "description":  "Beschreibung",
                        "type":  "object",
                        "required":  [
                            "description",
                            "agb"
                        ],
                        "properties":  {
                            "help1": {
                                "type": "string",
                                "title": "<h4>Wie sieht der Schaden aus, was ist zu reparieren?</h4>Fotografieren Sie den Schaden aus verschiedenen Positionen und geben Sie uns eine kurze Beschreibung, damit wir uns ein Bild machen können."
                            },
                            "description":  {
                                "type":  "string",
                                "title":  "Schadensbeschreibung"
                            },
                            "file":  {
                                "type":  "string",
                                "title":  "Laden Sie hier Fotos des Schadens hoch (max 15MB)"
                            },
                            "agb": {
                                "title": "Bei Auftragsvergabe fallen möglicherweise Kosten an. Die anfallenden Kosten entnehmen Sie bitte unseren AGB.",
                                "type":  "boolean"
                            }
                        }
                    },
                    "uischema":  {
                        "help1": {
                          "ui:widget": "help"
                        },
                        "description":  {
                            "ui:autofocus":  true,
                            "ui:emptyValue":  "",
                            "ui:autocomplete":  "family-name",
                            "ui:widget": "textarea"
                        },
                        "file":  {
                            "ui:widget": "file",
                        }
                    }
                },
                {
                    "title":  "Einsatzort und Auftraggeber",
                    "schema":  {
                        "title":  "Einsatzort und Auftraggeber",
                        "description":  "Einsatzort und Auftraggeber",
                        "type":  "object",
                        "required":  [
                            "street", "zipcode", "city",
                            "street_order", "zip_order", "city_order",
                            "surname", "firstname", "email", "phone"
                        ],
                        "properties":  {
                            "help1": {
                                "type": "string",
                                "title": "<h6>Daten zum Einsatzort</h6>"
                            },
                            "street_order":  {
                                "type":  "string",
                                "title":  "Straße, Hausnummer des Einsatzortes"
                            },
                            "zip_order":  {
                                "type":  "string",
                                "title":  "PLZ des Einsatzortes"
                            },
                            "city_order":  {
                                "type":  "string",
                                "title":  "Ortsname des Einsatzortes"
                            },
                            "help2": {
                                "type": "string",
                                "title": "<h6>Daten zum Auftraggeber</h6>"
                            },
                            "salutation":  {
                                "type":  "string",
                                "title": "Anrede",
                                "default": "Herr",
                                "enum": [
                                    "Herr",
                                    "Frau",
                                    "Andere"
                                ]
                            },
                            "surname":  {
                                "type":  "string",
                                "title": "Nachname"
                            },
                            "firstname":  {
                                "type":  "string",
                                "title": "Vorname"
                            },
                            "street":  {
                                "type":  "string"
                            },
                            "zipcode":  {
                                "type":  "string"
                            },
                            "city":  {
                                "title": "Ort",
                                "type":  "string"
                            },
                            "phone":  {
                                "title": "Telefon",
                                "type":  "string"
                            },
                            "email":  {
                                "title": "E-Mail",
                                "type":  "string"
                            },
                            "contact_type":  {
                                "title": "Kontaktart",
                                "type":  "string",
                                "default": "Email",
                                "enum": [
                                    "Telefon",
                                    "Email"
                                ]

                            }
                        }
                    },
                    "uischema":  {
                        "help1":  {
                            "ui:widget":  "help"
                        },
                        "help2":  {
                            "ui:widget":  "help"
                        },
                        "zip_order":  {
                            "ui:column":  "col-md-4"
                        },
                        "city_order":  {
                            "ui:column":  "col-md-4"
                        },
                        "contact_type": {

                        },
                        "street_order":  {
                            "ui:column":  "col-md-4"
                        },
                        "firstname": {
                            "ui:column": "col-md-5"
                        },
                        "surname": {
                            "ui:column": "col-md-5"
                        },
                        "zipcode":  {
                            "ui:column":  "col-md-4"
                        },
                        "salutation": {
                          "ui:column": "col-md-2"
                        },
                        "city":  {
                            "ui:column":  "col-md-8"
                        },
                        "street":  {
                            "ui:column":  "col-md-12"
                        },
                        "email":  {
                            "ui:column":  "col-md-6"
                        },
                        "phone":  {
                            "ui:column":  "col-md-6"
                        },
                        "telephone":  {
                            "ui:options":  {
                                "inputType":  "tel"
                            }
                        }
                    }
                },
                {
                    "title": "Datenschutz",
                    "schema": {
                        "title": "Einsatzort und Auftraggeber",
                        "description": "Einsatzort und Auftraggeber",
                        "type": "object",
                        "required": [
                            "privacy",
                            "captcha"
                        ],
                        "properties": {
                            "privacy": {
                                "type": "boolean",
                                "description": "asd",
                                "title": "Ich habe die Datenschutzerklärung zur Kenntnis genommen. Ich stimme zu, dass meine Angaben und Daten zur Beantwortung meiner Anfrage elektronisch erhoben und gespeichert werden. Hinweis: Sie können Ihre Einwilligung jederzeit für die Zukunft per E-Mail an uns widerrufen."
                            },
                            "captcha": {
                                "title": "Bitte geben Sie das nachfolgende Zeichen in das Textfeld ein",
                                "type": "string"
                            }
                        }
                    },
                    "uischema": {
                        "captcha": {
                            "ui:widget": "captcha"
                        }
                    }
                }
            ]
        };
    }

    renderSteps() {
        const colSpace = Math.floor(12 / this.getFormular().pages.length);
        return <div className={"row formular-header"}>
            {this.getFormular().pages.map((page: any, i: number) => {
                const activeStep = (i == this.state.step) ? "activestep": "";
                return <div className={activeStep+" stepgroup col-md-"+colSpace}>{i+1}. {page.title}</div>

            })}
        </div>
    }

    getCustomWidgets() {
        return {
            captcha: MyCaptchaWidget,
            help: MyHelpWidget,
            file: MyFileUploadWidget
        }
    }

    fireFormular(e) {
    //    e.preventDefault();
    //    e.stopPropagation();
        this.setState({loading: true, error: null});

        axios.post("https://universal.dasbad3.de/universalbackend/public/api/craftnote/sendFormular/"+this.state.config_id, {
                form: this.state.element,
                session_id: this.state.session_id,
                craftnote: this.getFormular().craftnote
            }).then(() => {
            this.setState({loading: false})
                if (!window.location.hostname.includes("localhost")) {
                    window.location = this.state.config.afterFormSuccessUrl
                }
            })
            .catch((err) => {
                this.setState({error: err.response.data.message, loading: false})
            })

        return false;
    }

    getPrivacyLink() {
        let url =  this.state.config.privacyLink;
        if (!url.startsWith("http")) {
            url = "https://"+url;
        }
        return url;
    }

    getAGBLink() {
        let url = this.state.config.agbLink;

        if (!url.startsWith("http")) {
            url = "https://"+url;
        }
        return url;
    }

    getImprintLink() {
        let url = this.state.config.imprintLink;

        if (!url.startsWith("http")) {
            url = "https://"+url;
        }
        return url;
    }

    public render() {

        const log = (type) => console.log.bind(console, type);

        if (this.state.error != null && this.state.config == null) {
            return <div className={"error"}>
                <div className={"red"}>Es gibt einen Fehler in der Formular Konfiguration.</div>
                {this.state.error}
            </div>
        }

        if (this.state.config == null) return null;

        const fullSchema = this.getFormular();
        const currentSchema = fullSchema.pages[this.state.step];

        return (
            <div className={"formular"}>
                <div>
                    {this.renderSteps()}
                </div>
                <Form schema={currentSchema.schema  as JSONSchema7}
                      formData={this.state.element}
                      uiSchema={currentSchema.uischema}
                      transformErrors={(errors) => {
                          return errors.map(error => {
                            // use error messages from JSON schema if any
                            // @ts-ignore
                            if (typeof fullSchema.errors[error.schemaPath] !== "undefined") {
                                return {
                                    ...error,
                                    // @ts-ignore
                                    message: fullSchema.errors[error.schemaPath]
                                };
                            }
                            if (typeof fullSchema.errors[error.name] !== "undefined") {
                                return {
                                    ...error,
                                    message: fullSchema.errors[error.name]
                                };
                            }
                            return error;
                        });
                      }}
                      formContext={{captcha: this.state.config.captcha}}
                      widgets={this.getCustomWidgets()}
                      ObjectFieldTemplate={this.columnsObjectFieldTemplate}
                      onChange={(e) => this.onFormChanged(e)}
                      onSubmit={(e) => this.onNextPressed(e)}
                      showErrorList={false}
                      onError={log("errors")} >
                    <div className={"error-message"}>
                        {this.state.error}
                    </div>
                    <div>
                        {this.state.step > 0 && <Button onClick={(e) => this.onPrevPressed(e)}>Zurück</Button>}
                        {this.state.step < this.getFormular().pages.length-1 && <Button style={{float: "right"}} type="submit">Weiter</Button>}
                        {this.state.step >= this.getFormular().pages.length-1 && <Button style={{float: "right"}} type="submit">{this.state.loading ? <div><ClipLoader color={"white"} size={20} /></div> : <div>Absenden</div>}</Button>}
                    </div>
                </Form>

                <div className={"footer"}>
                    <div>
                        <a href={this.getImprintLink()}>Impressum</a>
                    </div>
                    <div>
                        <a href={this.getAGBLink()}>AGB</a>
                    </div>
                    <div>
                        <a href={this.getPrivacyLink()}>Datenschutz</a>
                    </div>
                </div>
            </div>
        );
    }
}

declare let module: Record<string, any>;

export default hot(module)(Formular);
