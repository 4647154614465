import {useState} from "react";

export const MyFileUploadWidget = (props) => {

    let fileReader;
    let defaultValue = [];
    if (props.value) {
        defaultValue = JSON.parse(props.value);
    }
    const [files, setFiles] = useState(defaultValue);

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const guid = () => {
        let s4 = () => {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    const deleteFilEntry= (file) => {
        const nfiles = files.filter((m) => m.id != file.id);

        setFiles(nfiles);
        props.onChange(JSON.stringify(nfiles));
    }

    const handleFileChosen = (file) => {
        convertBase64(file)
            .then((data: any) => {

                const f = {
                    id: guid(),
                    name: file.name,
                    data: data
                };
                const newfiles = [...files, f];
                setFiles(newfiles);
                props.onChange(JSON.stringify(newfiles));
            } )

    };

    return (
            <div>
                <label className="form-label" htmlFor="customFile">{props.label}</label>
                <input type="file" className="form-control"
                       onChange={e => handleFileChosen(e.target.files[0])}/>
                <div className={"files"}>
                {files.map((m) => {
                    return <div className={"file-entry row"}><div className={"col-md-9"}>{m.name}</div> <div className={"col-md-3 right"}><a onClick={(e) => deleteFilEntry(m)} href={"#"}>Löschen</a></div></div>;
                })}
                </div>
            </div>
        );
};